import React, { useEffect, useState } from "react"
import Modal from "../Modal"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useClearUrlQuery } from "@/hooks/useQuery"
import { InfoIcon, TickIcon } from "../shared/icons"
import IconButton from "../IconButton"
import Image from "next/image"
import { useSignInStore } from "@/stores"
import Tooltip from "../Tooltip"
import Input from "../Input"
import TextCopy from "../TextCopy"
import ClientOnly from "../ClientOnly"
import { cn } from "@/lib/utils"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import client from "@/api/client"
import { ConfirmWalletRequest, ConnectWalletRequest } from "@/api/sdk"
import { warpNameLength } from "@/utils/parser"
import { useToast } from "@/hooks"
import { useWalletOwned } from "../Setting/Profile/WalletInfo"
import { useWeb3Modal, useWalletInfo, useWeb3ModalState } from "@web3modal/wagmi/react"
import { useAccount, useSignMessage } from "wagmi"
import { useDisconnect } from "wagmi"
import { cdnPublicFolderUrl } from "@/constants"

const LinkWalletAccountModalEvm = () => {
  const router = useCustomRouter()
  const clearUrl = useClearUrlQuery()
  const { signInModal, setSignInModal } = useSignInStore()
  const qc = useQueryClient()
  const toast = useToast()

  const [mode, setMode] = useState<
    "connect-wallet" | "no-wallet" | "link-account" | "wallet-already-linked" | "success"
  >("connect-wallet")
  const [isAboutToSignInWithWallet, setAboutToSignInWithWallet] = useState(false)
  const [isSigningInWithWallet, setSigningInWithWallet] = useState(false)

  const isOpen = (router.query.linkWalletAccountEvm === "true" || signInModal.linkWalletEvm) ?? false

  const { open } = useWeb3Modal()
  const { address, connector, isConnected, isConnecting, isDisconnected, isReconnecting, status } = useAccount()
  const { signMessageAsync } = useSignMessage()
  const { disconnectAsync } = useDisconnect()
  const { walletInfo } = useWalletInfo()
  const { open: openWalletModal } = useWeb3ModalState()

  const { data: walletOwned } = useWalletOwned()

  const onClose = () => {
    document.body.style.overflow = "unset"
    setSignInModal({ linkWalletEvm: false })
    setMode("connect-wallet")
    if (router.query.linkWalletAccountEvm === "true") {
      clearUrl("linkWalletAccountEvm")
    }
  }

  const { mutateAsync: mutateLinkWallet } = useMutation({
    mutationFn: (data: ConnectWalletRequest) => client.api.walletControllerConnectWallet(data).then(res => res.data),
    onError: (error: any) => {
      if (error.statusCode === 400 && error.message === "Wallet already connected to another account") {
        setMode("wallet-already-linked")
        return
      }

      toast({
        title: "Error",
        status: "error",
        message: [error.message ?? "Something went wrong, please try again later"],
      })
    },
  })

  const { mutate: mutateLinkWalletConfirm } = useMutation({
    mutationFn: (data: ConfirmWalletRequest) =>
      client.api.walletControllerConfirmConnectWallet(data).then(res => res.data),
    onSuccess: () => {
      qc.invalidateQueries({
        queryKey: ["wallet-owned"],
      })
      setMode("success")
    },
    onError: error => {
      console.error(error)
    },
  })

  const handleSwitchWallet = async () => {
    open()

    // if (isConnected) {
    //   setMode("connect-wallet")
    // }

    return setAboutToSignInWithWallet(true)
  }

  const submit = async () => {
    if (mode === "connect-wallet") {
      if (!isConnected) {
        open()
      }

      if (!address) {
        return setAboutToSignInWithWallet(true)
      }

      if (walletOwned?.wallets.find(item => item.id === address)) {
        onClose()
        return
      }

      setMode("link-account")
      return
    }

    if (mode === "link-account" || mode === "wallet-already-linked") {
      if (!isConnected || !address || !signMessageAsync) return

      if (walletOwned?.wallets.find(item => item.id === address)) {
        setMode("wallet-already-linked")

        return
      }

      try {
        const result = await mutateLinkWallet({
          address: address ?? "",
        })

        const signature = await signMessageAsync({ account: address, message: result.message })

        mutateLinkWalletConfirm({
          signature,
          address: result.address,
          timestamp: result.timestamp,
          provider: "Ethereum",
        })
      } catch (error) {
        console.error(error)
      }

      return
    }
  }

  useEffect(() => {
    if (!openWalletModal && isAboutToSignInWithWallet && !isConnecting) {
      setAboutToSignInWithWallet(false)
    }
  }, [isConnecting, openWalletModal, isAboutToSignInWithWallet])

  useEffect(() => {
    if (isConnected && mode === "connect-wallet" && isOpen) {
      if (walletOwned?.wallets.find(item => item.id === address)) {
        setMode("wallet-already-linked")
      } else {
        setMode("link-account")
      }
    }
  }, [isConnected, mode, walletOwned?.wallets, address, isOpen])

  useEffect(() => {
    if (!isConnected && !address) {
      setMode("connect-wallet")
    }
  }, [isConnected, address])

  const renderMode = () => {
    switch (mode) {
      case "no-wallet":
        return (
          <div>
            <p className="text-atherGray-300 mb-4">
              To complete this action, you will need to provide an Authentication Signature by installing a Browser that
              supports your wallet extension.
            </p>
            <p className="text-atherGray-300 mb-6">Note: We recommend you to use Chrome</p>
            <IconButton className="w-full py-2.5 rounded-xl" onClick={onClose}>
              Thank, I understand
            </IconButton>
          </div>
        )

      case "link-account":
        return (
          <div>
            <p className="text-atherGray-300 mb-6">
              This Ethereum wallet has not been linked to your GAIA account yet!
            </p>
            <div className="mb-6">
              <Input
                value={warpNameLength(address ?? "")}
                className="rounded-lg bg-atherGray-850"
                disabled
                rightIcon={<TextCopy value={address ?? ""} />}
              />
            </div>
            <IconButton onClick={() => submit()} className="w-full py-2.5 rounded-xl">
              Link wallet
            </IconButton>
            {walletInfo?.name && !isAboutToSignInWithWallet && !isSigningInWithWallet && (
              <div className="flex mt-2 items-center justify-center space-x-4">
                <button
                  type="button"
                  className="text-xs text-center text-red-500 min-w-[5rem] underline cursor-pointer"
                  onClick={async () => {
                    await disconnectAsync()
                    setMode("connect-wallet")
                  }}
                >
                  Disconnect
                </button>
              </div>
            )}
          </div>
        )

      case "wallet-already-linked":
        return (
          <div>
            <p className="text-atherGray-300 mb-6">
              This Ethereum wallet is already linked to{" "}
              {walletOwned?.wallets.find(item => item.id === address) ? "your" : "another"} GAIA account!
            </p>
            <div className="mb-6">
              <Input
                value={warpNameLength(address ?? "")}
                className="rounded-lg bg-atherGray-850"
                disabled
                rightIcon={<TextCopy value={address ?? ""} />}
              />
            </div>
            <p className="text-atherGray-300 mb-6">
              Please interact directly on the wallet extension to choose another wallet address to continue
            </p>
            {/* <div className="flex items-center mb-2">
              <p className="text-atherGray-300 mr-1">CRYPTO-WALLET</p>
              <Tooltip
                trigger={
                  <div>
                    <InfoIcon className="text-atherGray-600" width={14} height={14} />
                  </div>
                }
              >
                <p className="font-semibold text-sm mb-1">CRYPTO-WALLEt</p>
                <p className="text-atherGray-300 text-xs">
                  Wallets are used to send, receive, and store digital assets like Ethereum. Wallets come in many forms.
                  For more information about wallets, see this explanation.
                </p>
              </Tooltip>
            </div> */}
            <ClientOnly>
              <IconButton
                onClick={() => handleSwitchWallet()}
                colorScheme="secondary"
                className={cn("w-full py-3 rounded-xl bg-atherGray-850 justify-start overflow-hidden", {
                  "justify-center": isAboutToSignInWithWallet || isSigningInWithWallet,
                })}
                isLoading={isAboutToSignInWithWallet || isSigningInWithWallet}
              >
                <img src={`${cdnPublicFolderUrl}/eth-diamond-purple.png`} alt="Wallet Logo" width={32} height={32} />
                <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                  <p className="whitespace-nowrap">Ethereum Wallet</p>
                </div>
              </IconButton>
              {walletInfo?.name && !isAboutToSignInWithWallet && !isSigningInWithWallet && (
                <div className="flex mt-2 items-center justify-center space-x-4">
                  <button
                    type="button"
                    className="text-xs text-center text-atherGray-100 min-w-[5rem] underline cursor-pointer"
                    onClick={handleSwitchWallet}
                  >
                    Switch wallet
                  </button>
                  <button
                    type="button"
                    className="text-xs text-center text-red-500 min-w-[5rem] underline cursor-pointer"
                    onClick={async () => {
                      await disconnectAsync()
                      setMode("connect-wallet")
                    }}
                  >
                    Disconnect
                  </button>
                </div>
              )}
            </ClientOnly>
          </div>
        )

      case "success":
        return (
          <div className="flex flex-col items-center">
            <div className="rounded-full w-10 h-10 mb-6 bg-atherPurple-500 flex items-center justify-center">
              <TickIcon width={20} height={20} />
            </div>
            <h3 className="text-xl font-semibold text-center mb-4">Ethereum Wallet is linked to your GAIA account</h3>
            <h3 className="text-xl font-semibold text-center">Stay tuned for exclusive updates & rewards!</h3>
          </div>
        )

      default:
        return (
          <div>
            <p className="text-atherGray-300 mb-8">
              You can link multiple Ethereum wallets to your GAIA account. Please connect your wallet to the browser
              extension first and then link to your GAIA account.
            </p>
            {/* <div className="flex items-center mb-2">
              <p className="text-atherGray-300 mr-1">Crypto-wallet</p>
              <Tooltip
                trigger={
                  <div>
                    <InfoIcon className="text-atherGray-600" width={14} height={14} />
                  </div>
                }
              >
                <p className="font-semibold text-sm mb-1">CRYPTO-WALLET</p>
                <p className="text-atherGray-300 text-xs">
                  Wallets are used to send, receive, and store digital assets like Ethereum. Wallets come in many forms.
                  For more information about wallets, see this explanation.
                </p>
              </Tooltip>
            </div> */}
            <ClientOnly>
              <IconButton
                onClick={() => submit()}
                colorScheme="secondary"
                className={cn("w-full py-3 rounded-xl bg-atherGray-850 justify-start overflow-hidden", {
                  "justify-center": isAboutToSignInWithWallet || isSigningInWithWallet,
                })}
                isLoading={isAboutToSignInWithWallet || isSigningInWithWallet}
              >
                <img
                  src={walletInfo?.icon || `${cdnPublicFolderUrl}/eth-diamond-purple.png`}
                  alt="Wallet Logo"
                  width={32}
                  height={32}
                />
                <div className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                  <p className="whitespace-nowrap">
                    {walletInfo?.name
                      ? `${walletInfo?.name} (${address ? warpNameLength(address, 4, 6) : "Not connected"})`
                      : "Ethereum Wallet"}
                    <span className="text-green-500">
                      {walletOwned?.wallets.find(item => item.id === address) && ` (Linked)`}
                    </span>
                  </p>
                </div>
              </IconButton>
              {walletInfo?.name && !isAboutToSignInWithWallet && !isSigningInWithWallet && (
                <div className="flex mt-2 items-center justify-center space-x-4">
                  <button
                    type="button"
                    className="text-xs text-center text-atherGray-100 min-w-[5rem] underline cursor-pointer"
                    onClick={handleSwitchWallet}
                  >
                    Switch wallet
                  </button>
                  <button
                    type="button"
                    className="text-xs text-center text-red-500 min-w-[5rem] underline cursor-pointer"
                    onClick={async () => {
                      await disconnectAsync()
                    }}
                  >
                    Disconnect
                  </button>
                </div>
              )}
            </ClientOnly>
          </div>
        )
    }
  }

  return (
    <Modal
      isDisabledRemoveScroll={openWalletModal}
      key={mode}
      title={
        mode === "connect-wallet"
          ? "Connect your Ethereum wallet"
          : mode === "no-wallet"
            ? "No wallet extension detected"
            : mode === "link-account"
              ? "Link wallet to GAIA account!"
              : mode === "wallet-already-linked"
                ? "Wallet already in use"
                : undefined
      }
      className="overflow-visible max-w-md"
      isOpen={isOpen}
      onClose={onClose}
    >
      {renderMode()}
    </Modal>
  )
}

export default LinkWalletAccountModalEvm
